import gambar1 from "../asset/img/about/Foto 1.png";
import gambar2 from "../asset/img/about/Foto 2.jpg";
export const Profile = [
  {
    judul: "Visi Misi",
    desc: "Sensational snack for millenials. Create an unforgettable taste sensation The best quality product. become a loyal friend of millenials",
    photo: gambar1,
  },
  {
    judul: "Plan",
    desc: `To be a world class company in the food and beverage sector, With thousands of talented and passionate employees, all of them work as a solid team and have the same goal: to make the BEST Indonesian snacks. To be a world-class company of world-class people, providing world-class products and services, we really mean it.`,
    photo: gambar2,
  },
];
