import foto1 from "../asset/img/about/Foto 1.png";
import foto2 from "../asset/img/about/Foto 2.jpg";
import foto3 from "../asset/img/about/Foto 3.jpg";
import foto4 from "../asset/img/about/Photo 1.png";
import foto5 from "../asset/img/about/Photo 2.png";
import foto6 from "../asset/img/about/Photo 3.png";
import foto7 from "../asset/img/about/Photo 4.png";
import foto8 from "../asset/img/about/Photo 5.png";
import foto9 from "../asset/img/about/Photo 6.png";
import foto10 from "../asset/img/about/Photo 7.png";
import foto11 from "../asset/img/about/Photo 8.png";
import foto12 from "../asset/img/about/Photo 9.png";

export const Galery = [
  { foto: foto1 },
  { foto: foto2 },
  { foto: foto3 },
  { foto: foto4 },
  { foto: foto5 },
  { foto: foto6 },
  { foto: foto7 },
  { foto: foto8 },
  { foto: foto9 },
  { foto: foto10 },
  { foto: foto11 },
  { foto: foto12 },
];
